/* eslint-disable no-whitespace-before-property */
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import Jimp from 'jimp';
import {v4 as uuidV4} from 'uuid';
import IStoredFile, { StoredFileCollection } from "../StoredFile";

/**
 * Upload file to Firebase Storage.
 */
export async function UploadFile (files: FileList, collection: string): Promise<string> {
    const file = files [0];

    const fileName = `${uuidV4 ()}.${file.name.split ('.').pop ()}`;

    const [buffer, mime] = await PrepareFileForUploadAsJpngOrPng (file);

    const path = window.location.hostname === 'localhost' ? `localhost/${collection}/${fileName}` : `${collection}/${fileName}`;
    const now = Date.now ();

    const storedFile: IStoredFile = {
        path: path,
        expiration: now + (60 * 60 * 24 * 1000),
        created: now,
    };

    try {
        await firebase.firestore ().collection (StoredFileCollection)
        .add (storedFile);
    } catch (e) {
        console.error (e);
        return '';
    }

    return new Promise<string> ((resolve, reject) => {
        firebase.storage ().ref ().child (path)
        .put (buffer, {
            contentType: mime,
        })
        .then (snapshot => {
            resolve (path);
        })
        .catch (e => {
            console.error (`UploadFile: ${e}`);

            reject ();
        });
    });
}

/**
 * Resize file to a max size and change into JPG (except PNG) with decent quality.
 */
export function PrepareFileForUploadAsJpngOrPng (file: File): Promise<[Buffer, string]> {
    return new Promise ((resolve, reject) => {
        const reader = new FileReader ();

        reader.onerror = () => reject ();

        reader.onloadend = async () => {
            const dataUrl = reader.result as string;

            const jimp = await Jimp.read (dataUrl);

            const mime = jimp.getMIME ();

            if (jimp.getWidth () > 1080) {
                const scale = 1080 / jimp.getWidth ();

                jimp.resize (1080, jimp.getHeight () * scale);
            } else if (jimp.getHeight () > 1080) {
                const scale = 1080 / jimp.getHeight ();

                jimp.resize (jimp.getWidth () * scale, 1080);
            }

            jimp.quality (60);

            const buffer = await jimp.getBufferAsync (mime == 'image/png' ? Jimp.MIME_PNG : Jimp.MIME_JPEG);

            resolve ([buffer, mime == 'image/png' ? mime : 'image/jpeg']);
        };

        reader.readAsDataURL (file);
    });
}

/**
 * Get public url for file from Firebase Storage.
 */
export function FilePublicUrl (value: string): Promise<string> {
    return new Promise<string> ((resolve, reject) => {
        firebase.storage ().ref ().child (value)
        .getDownloadURL ()
        .then (url => {
            resolve (url);
        })
        .catch (e => {
            console.error (`FilePublicUrl: ${e}`);

            reject ();
        });
    });
}

/**
 * Delete StorageFile for path to stop expiration.
 */
export function DeleteStorageFileExpiration (path: string): Promise<void> {
    return new Promise ((resolve, reject) => {
        firebase.firestore ().collection (StoredFileCollection)
        .where ('path', '==', path)
        .limit (1)
        .get ()
        .then ((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.docs [0].ref.delete ();
            }

            resolve ();
        })
        .catch (e => {
            console.error (e);

            reject ();
        });
    });
}
