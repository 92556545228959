import { Container, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import AppBarSpacer from "./AppBarSpacer";
import { drawerWidth } from "./NavigationDrawer";
import ScreenAppBar from "./ScreenAppBar";

const useStyles = makeStyles ((theme: Theme) => ({
    screenContent: {
        overflow: 'hidden',
        paddingTop: theme.spacing (2),
    },
    screenContentWrapper: {
        paddingLeft: drawerWidth,
    }
}));

export interface Props {
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    children?: JSX.Element | JSX.Element [] | null;
    centered?: boolean;
}

/**
 * Render the component into html.
 */
function ScreenContent (props: Props) {
    const {maxWidth, children, centered} = props;

    const classes = useStyles ();

    return (
        <>
            <ScreenAppBar/>

            <div className={clsx (centered ? null : classes.screenContentWrapper)}>
                <AppBarSpacer/>

                <Container className={classes.screenContent} maxWidth={maxWidth}>
                    {children || ''}
                </Container>
            </div>
        </>
    );
}

export default ScreenContent;
