/* eslint-disable no-whitespace-before-property */
import { Button, IconButton, makeStyles, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext, IAppContext } from "../../App";
import ScreenContent from "../components/ScreenContent";
import firebase from "firebase/app";
import "firebase/firestore";
import IFeedback, { FeedbackCollection, IFeedbackStatus, FeedbackStatusToText } from "../../model/Feedback";
import { TruncateText } from "../../utils/Text";
import { ChevronRight, Delete } from "@material-ui/icons";
import AppPaper from "../components/AppPaper";
import ActionsPanel from "../components/ActionsPanel";
import {useHistory} from "react-router-dom";

export const kFeedbackListRoute = '/feedback';

export const useStyles = makeStyles ((theme: Theme) => ({
	tableImage: {
		height: 48,
	},
}));

/**
 * Render the component into html.
 */
function FeedbackListScreen () {
    const appContext = useContext (AppContext);

    const history = useHistory ();

    const classes = useStyles ();

    useEffect (() => {
        appContext.SetTitle ('Feedback');
    }, []);

    let defaultLimit = appContext.feedbacks.limit;
    if (window.location.hash) {
        const hash = window.location.hash.replace ('#', '').split ('&').map (params => params.split ('='));

        if (hash && hash.length > 0) {
            for (const params of hash) {
                if (params [0] === 'limit') {
                    defaultLimit = parseInt (params [1]);
                }
            }
        }
    }

    useEffect (() => {
        if (defaultLimit !== appContext.feedbacks.limit) {
            const newFeedbacks = {...appContext.feedbacks};
            newFeedbacks.limit = defaultLimit;
    
            appContext.SetFeedbacks (newFeedbacks);
        }
    }, []);

    useEffect (() => {
        let query = firebase.firestore ().collection (FeedbackCollection)
        .orderBy ('created', 'desc')
        .limit (appContext.feedbacks.limit);


        if (appContext.feedbacks.status != IFeedbackStatus.None) {
            query = query.where ('status', '==', appContext.feedbacks.status);
        }

        // .where ('app', '>=', 'emu') //TODO this allows search from start of field, needs lowercase searchable field
        // .where ('app', '<=', `emu~`)

        const unsubscribe = query.onSnapshot (snapshot => {
            if (snapshot.empty) {
                UpdateData (appContext, []);
            } else {
                UpdateData (appContext, snapshot.docs.map (doc => {
                    const feedback: IFeedback = doc.data () as any;
                    feedback.uid = doc.id;

                    return feedback;
                }));
            }
        });

        window.location.hash = `limit=${appContext.feedbacks.limit}`;

        return () => {
            unsubscribe ();
        };
    }, [appContext.feedbacks.limit, appContext.feedbacks.status]);

    const tableItems = appContext.feedbacks.data && appContext.feedbacks.data.length > 0 ? appContext.feedbacks.data.map (feedback => {
        const message = TruncateText (feedback.message, 60, true, true);

        const image = feedback.image ? (
            <a href={feedback.image} rel="noreferrer" target="_blank">
                <img className={classes.tableImage} src={`${feedback.image}?v=${feedback.uid}`} alt={feedback.image}/>
            </a>
        ) : 'N/A';

        return (
            <TableRow key={`feedback-${feedback.uid}`}>
                <TableCell>{FeedbackStatusToText (feedback.status)}</TableCell>
                <TableCell>{feedback.app}</TableCell>
                <TableCell>{feedback.name || 'Anonymous'}</TableCell>
                <TableCell>{feedback.email || 'N/A'}</TableCell>
                <TableCell>{feedback.subject || 'N/A'}</TableCell>
                <TableCell>{message}</TableCell>
                <TableCell>{image}</TableCell>
                <TableCell align="right" width="130px">
                    <IconButton onClick={() => DeleteFeedback (appContext, feedback.uid!)}><Delete/></IconButton>
                    <IconButton onClick={() => history.push (`/feedback/${feedback.uid}`)}><ChevronRight/></IconButton>
                </TableCell>
            </TableRow>
        );
    }) : (
        <TableRow>
            <TableCell colSpan={8} align="center">
                {appContext.feedbacks.data ? 'No Feedback found' : 'Loading...'}
            </TableCell>
        </TableRow>
    );

    const rightAction = appContext.feedbacks.data && appContext.feedbacks.data.length >= appContext.feedbacks.limit ? (
        <Button variant="outlined" onClick={() => LoadMore (appContext)}>More <ChevronRight/></Button>
    ) : undefined;

    return (
        <ScreenContent>
            <AppPaper>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>App</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Subject</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell width="130px" align="center">
                                <Button onClick={() => ResetFeedbacks (appContext)}>Reset</Button>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>
                                <TextField variant="outlined" value={appContext.feedbacks.status} fullWidth={true} select={true} onChange={(e: React.ChangeEvent<HTMLInputElement>) => SetStatus (appContext, e.target.value as any)}>
                                    <MenuItem value={IFeedbackStatus.None}>None</MenuItem>
                                    
                                    <MenuItem value={IFeedbackStatus.New}>{FeedbackStatusToText (IFeedbackStatus.New)}</MenuItem>
                                    
                                    <MenuItem value={IFeedbackStatus.InProgress}>{FeedbackStatusToText (IFeedbackStatus.InProgress)}</MenuItem>
                                    
                                    <MenuItem value={IFeedbackStatus.Done}>{FeedbackStatusToText (IFeedbackStatus.Done)}</MenuItem>
                                    
                                    <MenuItem value={IFeedbackStatus.Closed}>{FeedbackStatusToText (IFeedbackStatus.Closed)}</MenuItem>
                                </TextField>
                            </TableCell>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableItems}
                    </TableBody>
                </Table>

                <ActionsPanel rightAction={rightAction} marginTop={true}/>
            </AppPaper>
        </ScreenContent>
    );
}

/**
 * Delete Feedback from DB by id.
 */
function DeleteFeedback (appContext: IAppContext, uid: string) {
    appContext.SetModals ({
        confirm: {
            open: true,
            text: 'Do you want to delete this Feedback?',
            onConfirm: (confirmed: boolean) => {
                if (confirmed) {
                    firebase.firestore ().collection (FeedbackCollection).doc (uid).delete ();
                }
            },
        },
    });
}

/**
 * Update feedbacks data for snapshot.
 */
function UpdateData (appContext: IAppContext, feedbacks: IFeedback []) {
    appContext.SetFeedbacks ((prev) => {
        return {
            ...prev,
            data: feedbacks,
        };
    });
}

/**
 * Load more feedbacks by one page.
 */
function LoadMore (appContext: IAppContext) {
    appContext.SetFeedbacks ((prev) => {
        return {
            ...prev,
            limit: prev.limit + 10,
        };
    });
}

/**
 * Set Feedback status filter.
 */
function SetStatus (appContext: IAppContext, newStatus: IFeedbackStatus) {
    appContext.SetFeedbacks ((prev) => {
        return {
            ...prev,
            status: newStatus,
        };
    });
}

/**
 * Reset filters, pagination to default state.
 */
function ResetFeedbacks (appContext: IAppContext) {
    appContext.SetFeedbacks ((prev) => {
        return {
            ...prev,
            limit: 10,
            status: IFeedbackStatus.None,
        };
    });
}

export default FeedbackListScreen;
