import { IconButton, makeStyles, Theme } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import clsx from "clsx";
import { useContext } from "react";
import { AppContext, IAppContext } from "../../../App";
import { InputProps } from "./InputProps";

const useStyles = makeStyles ((theme: Theme) => ({
    label: {
        marginLeft: 14,
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
    },
    chipsContainer: {
        padding: theme.spacing (1),
        paddingBottom: 0,
    },
    chip: {
        display: 'inline-block',
        padding: '2px 4px',
        borderRadius: 4,
        marginRight: theme.spacing (1),
        marginBottom: theme.spacing (1),
        background: theme.palette.grey[200],
    },
    chipLabel: {
        verticalAlign: 'middle',
        paddingRight: theme.spacing (1),
    },
}));

/**
 * Render the component into html.
 */
function Chips (props: InputProps) {
    const {className, value, label, index, UpdateValue} = props;

    const appContext = useContext (AppContext);

    const classes = useStyles ();

    const items = value ? value.split (',') : [];

    /**
     * Remove Chip from value.
     */
    const RemoveChip = (itemIndex: number) => {
        items.splice (itemIndex, 1);

        UpdateValue (index, items.length > 0 ? items.join (',') : '');
    };

    const chips = items.map ((item, itemIndex) => {
        return (
            <div className={classes.chip} key={item}>
                <span className={classes.chipLabel}>{item}</span>

                <IconButton size="small" onClick={() => RemoveChip (itemIndex)}><Close/></IconButton>
            </div>
        );
    });

    return (
        <div className={clsx ('Form_Chips', className)}>
            <span className={classes.label}>{label}</span>

            <div className={classes.chipsContainer}>
                {chips}

                <IconButton size="small" onClick={() => AddChip (appContext, props)}><Add/></IconButton>
            </div>
        </div>
    );
}

/**
 * Add new Chip, use dialog for text value.
 */
function AddChip (appContext: IAppContext, props: InputProps) {
    const {value: chips, index, UpdateValue} = props;

    appContext.SetModals ({
        value: {
            open: true,
            value: 'New Chip',
            onConfirm: (value: string) => {
                if (value) {
                    const newChips = chips ? `${chips},${value}` : value;

                    UpdateValue (index, newChips);
                }
            },
        },
    });
}

export default Chips;
