import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { formUseStyles } from "../Form";
import { TextAreaProps } from "./InputProps";

/**
 * Render the component into html.
 */
function TextArea (props: TextAreaProps) {
	const {index, id, className, label, value, UpdateValue, rows, hidden, error, errorMessage} = props;

    const formClasses = formUseStyles ();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        UpdateValue (index, e.target.value);
    };

	return (
		<div className={clsx ('Form_TextArea', className, (hidden ? formClasses.hidden : undefined))}>
			<TextField id={id} label={label} value={value} variant="outlined" error={error} helperText={error ? errorMessage : undefined} fullWidth={true} margin="normal" multiline={true} rows={rows} onChange={OnChange}/>
		</div>
	);
}

export default TextArea;
