/* eslint-disable no-whitespace-before-property */
import { Button, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import { AppContext } from "../../App";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import Form, { InputsData, InputType, ValidateForm } from "../components/Form";
import ScreenContent from "../components/ScreenContent";
import firebase from "firebase/app";
import "firebase/firestore";
import IInfo, { InfoCollection, IInfoType, InfoTypeToString } from "../../model/Info";
import { ChevronLeft } from "@material-ui/icons";
import { DeleteStorageFileExpiration, FilePublicUrl, UploadFile } from "../../model/providers/StorageProvider";
import { FetchAppsAggregate } from "../../model/providers/AggregateProvider";
import { Option } from "../components/form/InputProps";
import ITranslation from "../../model/Translation";

export const kInfoCreateRoute = '/info/create';
export const kInfoEditRoute = '/info/:infoId/edit';

export interface Params {
    infoId?: string;
}

/**
 * Render the component into html.
 */
function InfoEditScreen () {
    const {infoId} = useParams<Params> ();

    const appContext = useContext (AppContext);

    useEffect (() => {
        appContext.SetTitle (infoId ? 'Info Edit' : 'Info Creation');
    }, []);

    const history = useHistory ();

    /**
     * Update required of fields by type.
     */
    const UpdateRequiredFields = (inputs: InputsData, type: IInfoType, skipSetState: boolean = false): InputsData => {
        const languages = inputs.languages.value ? inputs.languages.value.split (',') : [];

        const newInputs = {...inputs};

        newInputs.fullImage.required = undefined;
        newInputs.image.required = undefined;
        newInputs.text.required = undefined;

        newInputs.fullImage.hidden = undefined;
        newInputs.image.hidden = undefined;
        newInputs.text.hidden = undefined;

        for (const lang of languages) {
            newInputs [`text_${lang}`].required = undefined;
            newInputs [`text_${lang}`].hidden = undefined;
        }

        if (type === IInfoType.TextOnly) {
            newInputs.text.required = true;

            for (const lang of languages) {
                newInputs [`text_${lang}`].required = true;
            }

            newInputs.fullImage.hidden = true;
            newInputs.image.hidden = true;
        } else if (type === IInfoType.ImageText) {
            newInputs.image.required = true;
            newInputs.text.required = true;

            for (const lang of languages) {
                newInputs [`text_${lang}`].required = true;
            }

            newInputs.fullImage.hidden = true;
        } else if (type === IInfoType.FullImageOnly) {
            newInputs.fullImage.required = true;

            newInputs.image.hidden = true;
            newInputs.text.hidden = true;

            for (const lang of languages) {
                newInputs [`text_${lang}`].hidden = true;
            }
        }

        if (!skipSetState) {
            setInputs (newInputs);
        }

        return newInputs;
    };
    
    let [inputs, setInputs] = useState<InputsData> ({
        type: {
            type: InputType.Select,
            label: 'Type',
            value: '',
            required: true,
            options: [
                {
                    label: InfoTypeToString (IInfoType.TextOnly),
                    value: IInfoType.TextOnly,
                },
                {
                    label: InfoTypeToString (IInfoType.ImageText),
                    value: IInfoType.ImageText,
                },
                {
                    label: InfoTypeToString (IInfoType.FullImageOnly),
                    value: IInfoType.FullImageOnly,
                },
            ],
            OnChange: (index: string, value: string) => UpdateRequiredFields (inputs, parseInt (value)),
        },
        languages: {
            type: InputType.Chips,
            label: 'Languages',
            value: '',
        },
        headline: {
            type: InputType.Text,
            label: 'Headline',
            value: '',
            required: true,
        },
        fullImage: {
            type: InputType.File,
            label: 'FullImage',
            value: '',
            UploadFile: (files: FileList) => UploadFile (files, InfoCollection),
            FilePublicUrl: FilePublicUrl,
        },
        image: {
            type: InputType.File,
            label: 'Image',
            value: '',
            UploadFile: (files: FileList) => UploadFile (files, InfoCollection),
            FilePublicUrl: FilePublicUrl,
        },
        text: {
            type: InputType.TextArea,
            label: 'Text',
            value: '',
            rows: '4',
        },
        conditions: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            Render: () => (
                <Typography variant="h5">
                    Conditions for display
                </Typography>
            ),
        },
        app: {
            type: InputType.Select,
            label: 'App',
            value: '',
            options: [],
        },
        version: {
            type: InputType.Text,
            label: 'Version',
            value: '',
        },
        /* from: {
            type: InputType.DatePicker,
            label: 'From',
            value: undefined,
        },
        to: {
            type: InputType.DatePicker,
            label: 'To',
            value: undefined,
        }, */
        enabled: {
            type: InputType.CheckBox,
            label: 'Enabled',
            value: false,
        },
        debugOnly: {
            type: InputType.CheckBox,
            label: 'Debug build only',
            value: false,
        },
        data: {
            type: InputType.CustomRenderer,
            label: '',
            value: '',
            Render: () => (
                <Typography variant="h5">
                    Data for actions
                </Typography>
            ),
        },
        url: {
            type: InputType.Text,
            label: 'Url',
            value: '',
            requireUri: true,
        },
        payload: {
            type: InputType.TextArea,
            label: 'Payload (JSON)',
            value: '',
            rows: '4',
        },
    });

    inputs.type.OnChange = (index: string, value: string) => UpdateRequiredFields (inputs, parseInt (value));

    inputs = InputsForLanguages (inputs);

    inputs = UpdateRequiredFields (inputs, parseInt (inputs.type.value), true);

    useEffect (() => {
        FetchAppsAggregate (appContext)
            .then (aggregate => {
                const newInputs = {...inputs};

                if (aggregate) {
                    const options: Option [] = aggregate.value;

                    newInputs.app.options = options;
                } else {
                    newInputs.app.options = [];
                }

                setInputs (newInputs);
            })
            .catch (e => console.error (e));
    }, []);

    const [existingInfo, setExistingInfo] = useState<IInfo> ();

    useEffect (() => {
        if (infoId) {
            firebase.firestore ().collection (InfoCollection)
            .doc (infoId)
            .get ()
            .then (doc => {
                if (doc.exists) {
                    const docInfo: IInfo = doc.data () as any;
                    docInfo.uid = doc.id;

                    let newInputs = {...inputs};

                    newInputs.type.value = `${docInfo.type}`;
                    newInputs.languages.value = docInfo.languages || '';
                    newInputs.headline.value = docInfo.headline;
                    newInputs.fullImage.value = docInfo.fullImage || '';
                    newInputs.image.value = docInfo.image || '';
                    newInputs.text.value = docInfo.text;
                    newInputs.app.value = docInfo.app || '';
                    newInputs.version.value = docInfo.version || '';
                    /* newInputs.from.value = docInfo.from ? docInfo.from * 1000 : undefined;
                    newInputs.to.value = docInfo.to ? docInfo.to * 1000 : undefined; */
                    newInputs.enabled.value = docInfo.enabled || false;
                    newInputs.debugOnly.value = docInfo.debugOnly || false;
                    newInputs.url.value = docInfo.url;
                    newInputs.payload.value = docInfo.payload;

                    newInputs = InputsForLanguages (newInputs);

                    if (docInfo.headlineTranslations) {
                        for (const translation of docInfo.headlineTranslations) {
                            newInputs [`headline_${translation.language}`].value = translation.value;
                        }
                    }

                    if (docInfo.textTranslations) {
                        for (const translation of docInfo.textTranslations) {
                            newInputs [`text_${translation.language}`].value = translation.value;
                        }
                    }
                    
                    setInputs (newInputs);

                    setExistingInfo (docInfo);

                    setTimeout (() => UpdateRequiredFields (newInputs, parseInt (newInputs.type.value)), 200);
                } else {
                    setExistingInfo (undefined);
                }
            })
            .catch (e => {
                console.error (`InfoEditScreen init Info: ${e}`);
            });
        }
    }, []);

    /**
     * Save Info to Firestore.
     */
    const Save = async () => {
        if (ValidateForm (inputs, (inputs: InputsData) => setInputs (inputs))) {
            const now = Date.now ();

            const languages = inputs.languages.value ? inputs.languages.value.split (',') : [];

            let headlineTranslations: ITranslation [] = [];
            let textTranslations: ITranslation [] = [];

            for (const lang of languages) {
                headlineTranslations.push ({
                    language: lang,
                    value: inputs [`headline_${lang}`].value,
                });

                textTranslations.push ({
                    language: lang,
                    value: inputs [`text_${lang}`].value,
                });
            }

            const info: IInfo = {
                type: parseInt (inputs.type.value),
                languages: inputs.languages.value,
                headline: inputs.headline.value,
                headlineSearchable: inputs.headline.value.toLocaleLowerCase (),
                headlineTranslations: headlineTranslations,
                fullImage: inputs.fullImage.value,
                image: inputs.image.value,
                text: inputs.text.value,
                textTranslations: textTranslations,
                app: inputs.app.value,
                version: inputs.version.value,
                /* from: inputs.from.value ? inputs.from.value / 1000 : undefined,
                to: inputs.to.value ? inputs.to.value / 1000 : undefined, */
                enabled: inputs.enabled.value,
                debugOnly: inputs.debugOnly.value,
                url: inputs.url.value,
                payload: inputs.payload.value,
                updated: now,
                created: existingInfo ? existingInfo.created : now,
            };

            try {
                if (infoId) {
                    await firebase.firestore ().collection (InfoCollection)
                    .doc (infoId)
                    .set (info);

                    history.push (`/info/${infoId}`);
                } else {
                    const result = await firebase.firestore ().collection (InfoCollection)
                    .add (info);

                    history.push (`/info/${result.id}`);
                }

                if (info.fullImage) {
                    DeleteStorageFileExpiration (info.fullImage);
                }

                if (info.image) {
                    DeleteStorageFileExpiration (info.image);
                }
            } catch (e) {
                console.error (e);
            }
        }
    };

    let contents = (<div/>);

    if (infoId && !existingInfo) {
        contents = (
            <Typography>
                Info does not exist
            </Typography>
        );
    } else {
        contents = (
            <>
                <Form id="info-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

                <ActionsPanel rightAction={
                    <Button variant="contained" color="primary" onClick={() => Save ()}>{infoId ? 'Update' : 'Create'}</Button>
                }/>
            </>
        );
    }

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link} to={infoId ? `/info/${infoId}` : '/info'}><ChevronLeft/></Button>
            }/>

            <AppPaper>
                {contents}
            </AppPaper>
        </ScreenContent>
    );
}

/**
 * Update inputs for languages.
 */
function InputsForLanguages (inputs: InputsData): InputsData {
    const newInputs: InputsData = {};

    const languages = inputs.languages.value ? inputs.languages.value.split (',') : [];

    for (const index of Object.keys (inputs)) {
        newInputs [index] = inputs [index];

        if (languages.length > 0) {
            if (index === 'headline') {
                for (const lang of languages) {
                    newInputs [`headline_${lang}`] = {
                        type: InputType.Text,
                        label: `Headline (${lang})`,
                        value: '',
                        required: true,
                    };
                }
            } else if (index === 'text') {
                for (const lang of languages) {
                    newInputs [`text_${lang}`] = {
                        type: InputType.TextArea,
                        label: `Text (${lang})`,
                        value: '',
                        rows: '4',
                    };
                }
            }
        }
    }

    for (const index of Object.keys (newInputs)) {
        let shouldExist = index.indexOf ('headline_') < 0 && index.indexOf ('text_') < 0;

        if (index.indexOf ('headline_') >= 0) {
            for (const lang of languages) {
                if (index === `headline_${lang}`) {
                    shouldExist = true;
                    break;
                }
            }
        } else if (index.indexOf ('text_') >= 0) {
            for (const lang of languages) {
                if (index === `text_${lang}`) {
                    shouldExist = true;
                    break;
                }
            }
        }

        if (!shouldExist) {
            delete newInputs [index];
        }
    }

    return newInputs;
}

export default InfoEditScreen;
