import { Button, makeStyles, Theme } from "@material-ui/core";
import { useContext } from "react";
import { AppContext } from "../../App";
import AppModal from "./AppModal";

export const useStyles = makeStyles ((theme: Theme) => ({
    action: {
        marginRight: theme.spacing (1),
    },
}));

/**
 * Render the component into html.
 */
function Modals () {
    const classes = useStyles ();

    const appContext = useContext (AppContext);

    const {modals} = appContext;

    const modalsJSX: JSX.Element [] = [];

    if (modals.confirm) {
        /**
         * Hide the modal.
         */
        const confirmNo = () => {
            const newModals = {...appContext.modals};
            newModals.confirm!.open = false;

            appContext.SetModals (newModals);

            modals.confirm!.onConfirm (false);
        };

        /**
         * Hide the modal and execute confirm action.
         */
        const confirmYes = () => {
            const newModals = {...appContext.modals};
            newModals.confirm!.open = false;

            appContext.SetModals (newModals);

            modals.confirm!.onConfirm (true);
        };

        modalsJSX.push ((
            <AppModal key="confirm-modal" open={modals.confirm.open} text={modals.confirm.text} actions={(
                <>
                    <Button key="action-no" className={classes.action} variant="contained" onClick={confirmNo}>No</Button>

                    <Button key="action-yes" variant="contained" color="primary" onClick={confirmYes}>Yes</Button>
                </>
            )}/>
        ));
    }

    if (modals.value) {
        /**
        * Hide the modal.
        */
       const valueCancel = () => {
           const newModals = {...appContext.modals};
           newModals.value!.open = false;

           appContext.SetModals (newModals);
       };

        /**
         * Hide the modal and execute confirm action.
         */
        const valueConfirm = () => {
            const newModals = {...appContext.modals};
            newModals.value!.open = false;

            appContext.SetModals (newModals);

            modals.value!.onConfirm (newModals.value!.value);
        };

        modalsJSX.push ((
            <AppModal key="value-modal" open={modals.value.open} value={modals.value.value} actions={(
                <>
                    <Button key="action-cancel" className={classes.action} variant="contained" onClick={valueCancel}>Cancel</Button>

                    <Button key="action-confirm" variant="contained" color="primary" onClick={valueConfirm}>Confirm</Button> 
                </>
            )}/>
        ));
    }

    return (
        <div id="Modals">
            {modalsJSX}
        </div>
    );
}

export default Modals;
