import { Checkbox as MaterialCheckbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { formUseStyles } from "../Form";
import { CheckBoxProps } from "./InputProps";

/**
 * Render the component into html.
 */
function CheckBox (props: CheckBoxProps) {
    const {index, id, className, label, value, UpdateValue, hidden, error, errorMessage} = props;

    const formClasses = formUseStyles ();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        UpdateValue (index, e.target.checked);
    };

    return (
        <div className={clsx ('Form_CheckBox', className, (hidden ? formClasses.hidden : undefined))}>
            <FormControlLabel control={<MaterialCheckbox checked={value} onChange={OnChange}/>} label={label} labelPlacement="end"/>
        </div>
    );
}

export default CheckBox;
