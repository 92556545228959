import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Toolbar } from "@material-ui/core";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import DrawerItem from "../../model/drawer/DrawerItem";

export const drawerWidth = 304;

const useStyles = makeStyles ((theme: Theme) => ({
	drawer: {
		width: drawerWidth
	},
	drawerSubmenu: {
		width: '100%',
		padding: 0
	},
}));

/**
 * Render the component into html.
 */
function NavigationDrawer () {
	const appContext = useContext (AppContext);

    const classes = useStyles ();

	if (appContext.authUser) {
		const drawerListItems = NavigationDrawerItems ().map (element => {
			let isSelected = (element.exact && element.to === window.location.pathname) || (!element.exact && window.location.pathname.indexOf (element.to) === 0);
			if (!isSelected && element.children) {
				for (const child of element.children) {
					if ((child.exact && child.to === window.location.pathname) || (!child.exact && window.location.pathname.indexOf (child.to) === 0)) {
						isSelected = true;
						break;
					}
				}
			}

			let children = null;
			if (element.children && isSelected) {
				children = element.children.map (childElement => (
					<ListItem button={true} key={`${element.to}-${childElement.to}`} component={Link} to={childElement.to} selected={childElement.to === window.location.pathname}>
						<ListItemText secondary={childElement.text}/>
					</ListItem>
				));

				children = (
					<ul className={classes.drawerSubmenu}>
						{children}
					</ul>
				);
			}

			return (
				<li key={element.to}>
					<ListItem button={true} component={Link} to={element.to} selected={isSelected}>
						{element.icon ? <ListItemIcon>{element.icon}</ListItemIcon> : null}
						<ListItemText primary={element.text}/>
					</ListItem>

					{children}
				</li>
			);
		});

		return (
			<Drawer className={classes.drawer} variant="permanent">
				<Toolbar/>

				<List className={classes.drawer}>
					{drawerListItems}
				</List>
			</Drawer>
		);
	} else {
		return null;
	}
}

/**
 * Get list of items for navigation.
 */
function NavigationDrawerItems (): DrawerItem [] {
	const items: DrawerItem [] = [
		{
			text: 'Dashboard',
			to: '/',
			exact: true,
		},
		{
			text: 'Feedback',
			to: '/feedback',
		},
		{
			text: 'Info',
			to: '/info',
			children: [
				{
					text: 'Create Info',
					to: '/info/create',
				},
			],
		},
		{
			text: 'Logout',
			to: '/logout',
		},
	];

	return items;
}

export default NavigationDrawer;
