import './app.css';

import { AppBar, createMuiTheme, CssBaseline, MuiThemeProvider, Theme, Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/auth";
import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom';
import Cog from "./icons/Cog";
import IAuthUser from "./model/AuthUser";
import Router from "./Router";
import clsx from 'clsx';
import NavigationDrawer from './ui/components/NavigationDrawer';
import IFeedback, { IFeedbackStatus } from './model/Feedback';
import IInfo from './model/Info';
import Modals from './ui/components/Modals';
import IAggregate from './model/Aggreagte';

export const AppContext = createContext<IAppContext> ({
	SetTitle: (title) => {},
	SetAuthUser: (authUser) => {},
	feedbacks: {
		limit: 10,
		status: IFeedbackStatus.None,
	},
	SetFeedbacks: (feedbacks) => {},
	infos: {
		limit: 10,
	},
	SetInfos: (infos) => {},
	modals: {},
	SetModals: (modals) => {},
	aggregates: {},
	SetAggregates: (aggregates) => {},
});

export interface IAppContext {
	title?: string;
	SetTitle: Dispatch<SetStateAction<string|undefined>>;
	authUser?: IAuthUser;
	SetAuthUser: Dispatch<SetStateAction<IAuthUser|undefined>>;
	feedbacks: IAppContextFeedbacks;
	SetFeedbacks: Dispatch<SetStateAction<IAppContextFeedbacks>>;
	infos: IAppContextInfos;
	SetInfos: Dispatch<SetStateAction<IAppContextInfos>>;
	modals: IAppContextModals;
	SetModals: Dispatch<SetStateAction<IAppContextModals>>;
	aggregates: IAppContextAggregates;
	SetAggregates: Dispatch<SetStateAction<IAppContextAggregates>>;
}

export interface IAppContextFeedbacks {
	limit: number;
	status: IFeedbackStatus;
	data?: IFeedback [];
}

export interface IAppContextInfos {
	limit: number;
	data?: IInfo [];
}

export interface IAppContextModals {
	confirm?: {
		open: boolean;
		text: string;
		onConfirm: (confirmed: boolean) => void;
	};
	value?: {
		open: boolean;
		value: string;
		onConfirm: (value: string) => void;
	};
}

export interface IAppContextAggregates {
	apps?: IAggregate;
}

const title = document.getElementById ('document-title');
const appTitle = title && title.dataset.title ? title.dataset.title : '';

const theme = createMuiTheme ({});

export const appUseStyles = makeStyles ((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	displayFlex: {
		display: "flex"
	},
	flexGrow: {
		flexGrow: 1
	},
	icon: {
		width: 48,
		height: 48,
	},
}));

/**
 * Render the component into html.
 */
export default function App () {
	const [title, setTitle] = useState<string> ();

	useEffect (() => {
		document.title = title ? `${title} - ${appTitle}` : appTitle;
	}, [title]);

	const [authUser, setAuthUser] = useState<IAuthUser> ();
	const [authUserVerified, setAuthUserVerified] = useState<boolean> (false);

	useEffect (() => {
		firebase.auth ().onAuthStateChanged (user => {
			if (user) {
				const authUser: IAuthUser = {
					email: user.email!,
					emailVerified: user.emailVerified,
					uid: user.uid,
				};

				setAuthUser (authUser);
			} else {
				setAuthUser (undefined);
			}

			setAuthUserVerified (true);
		});
	}, []);

	const [feedbacks, setFeedbacks] = useState<IAppContextFeedbacks> ({
		limit: 10,
		status: IFeedbackStatus.None,
	});

	const [infos, setInfos] = useState<IAppContextInfos> ({
		limit: 10,
	});

	const [modals, setModals] = useState<IAppContextModals> ({});

	const [aggregates, setAggregates] = useState<IAppContextAggregates> ({});

	const appContext: IAppContext = {
		title,
		SetTitle: setTitle,
		authUser,
		SetAuthUser: setAuthUser,
		feedbacks,
		SetFeedbacks: setFeedbacks,
		infos,
		SetInfos: setInfos,
		modals,
		SetModals: setModals,
		aggregates,
		SetAggregates: setAggregates,
	};

	const appClasses = ['App'];

	let renderContents = (
		<>
			<BrowserRouter>
				<NavigationDrawer/>

				<main id="content">
					<Router/>
				</main>

				<Modals/>
			</BrowserRouter>
		</>
	);

	if (!authUserVerified) {
		appClasses.push ('center-content');

		renderContents = (
			<Cog spin={true}/>
		);
	}

	return (
		<AppContext.Provider value={appContext}>
			<MuiThemeProvider theme={theme}>
				<div className={clsx (appClasses)} id="app">
					<CssBaseline/>

					{renderContents}
				</div>
			</MuiThemeProvider>
		</AppContext.Provider>
	);
}
