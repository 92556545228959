import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config, { defaultRegion } from './firebase-config';
import reportWebVitals from './reportWebVitals';

firebase.initializeApp (config);

if (window.location.hostname === 'localhost') {
	firebase.firestore ().useEmulator ('localhost', 8080);

	firebase.app ().functions (defaultRegion).useEmulator ('localhost', 5001);
	firebase.functions ().useEmulator ('localhost', 5001);
}

// ReactDOM.render (
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>,
// 	document.getElementById ('root')
// );
ReactDOM.render (
	<App />,
	document.getElementById ('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals ();
