import { Fade, makeStyles, Modal, Paper, TextField, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";
import { AppContext } from "../../App";

export const useStyles = makeStyles ((theme: Theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	modalActions: {
		display: "flex",
		justifyContent: "flex-end"
	},
	modalPaper: {
        minWidth: '240px',
		maxWidth: '480px',
		padding: theme.spacing (1),
		outline: 'none'
	},
    smallSpacer: {
        display: 'block',
        height: theme.spacing (1),
    },
}));

export interface Props {
    className?: string;
    open: boolean;
    text?: string;
    value?: string;
    body?: JSX.Element;
    actions: JSX.Element;
}

/**
 * Render the component into html.
 */
function AppModal (props: Props) {
    const {className, open, text, value, body, actions} = props;

    const appContext = useContext (AppContext);

    const classes = useStyles ();

    const textJSX = text ? (
        <Typography paragraph={true}>
            {text}
        </Typography>
    ) : null;

    /**
     * OnChange update ValueModal value.
     */
    const OnChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const modals = {...appContext.modals};

        if (modals.value) {
            modals.value.value = e.target.value;
        }

        appContext.SetModals (modals);
    };

    const valueJSX = typeof value !== 'undefined' ? (
        <>
            <TextField value={value} variant="outlined" fullWidth={true} margin="normal" onChange={OnChangeValue}/>

            <div className={classes.smallSpacer}/>
        </>
    ) : null;

    return (
        <Modal className={clsx (classes.modal, className)} open={open}>
            <Fade in={open}>
                <Paper className={classes.modalPaper}>
                    {textJSX}

                    {valueJSX}

                    {body}

                    <div className={classes.modalActions}>
                        {actions}
                    </div>
                </Paper>
            </Fade>
        </Modal>
    );
}

export default AppModal;
