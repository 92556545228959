import * as faker from 'faker';
import firebase from "firebase/app";
import "firebase/firestore";
import IFeedback, { FeedbackCollection, IFeedbackStatus } from "../model/Feedback";
import IInfo, { InfoCollection, IInfoType } from '../model/Info';

/**
 * Fill the DB with dummy data if dev.
 */
export async function FillDBForEmulator () {
	if (window.location.hostname === 'localhost') {
		for (let i = 0; i < 100; i++) {
			const now = Date.now ();

			const name = faker.random.boolean () ? faker.name.findName () : undefined;
			const email = faker.random.boolean () ? faker.internet.email () : undefined;
			const subject = faker.random.boolean () ? faker.lorem.words () : undefined;

			const feedback: IFeedback = {
				app: faker.lorem.slug (),
				name: name,
				nameSearchable: name ? name.toLocaleLowerCase () : undefined,
				email: email,
				emailSearchable: email ? email.toLocaleLowerCase () : undefined,
				subject: subject,
				subjectSearchable: subject ? subject.toLocaleLowerCase () : undefined,
				message: faker.lorem.paragraphs (faker.random.number (2)),
				image: faker.random.boolean () ? faker.image.imageUrl () : undefined,
				status: faker.random.boolean () ? IFeedbackStatus.New : IFeedbackStatus.Done,
				updated: now,
				created: now,
			};

			await firebase.firestore ().collection (FeedbackCollection).add (JSON.parse (JSON.stringify (feedback)));
		}

		let now = Date.now ();

		let info: IInfo = {
			languages: '',
			type: IInfoType.TextOnly,
			headline: 'Unrestricted',
			headlineSearchable: 'Unrestricted'.toLocaleLowerCase (),
			text: faker.lorem.paragraphs (1),
			enabled: true,
			updated: now,
			created: now,
		};

		await firebase.firestore ().collection (InfoCollection).add (info);

		now = Date.now ();

		info = {
			type: IInfoType.TextOnly,
			languages: '',
			headline: 'Debes Only',
			headlineSearchable: 'Debes Only'.toLocaleLowerCase (),
			text: faker.lorem.paragraphs (1),
			app: 'debes_flutter',
			enabled: true,
			updated: now,
			created: now,
		};

		await firebase.firestore ().collection (InfoCollection).add (info);

		now = Date.now ();

		info = {
			type: IInfoType.TextOnly,
			languages: '',
			headline: 'Debes w Version',
			headlineSearchable: 'Debes w Version'.toLocaleLowerCase (),
			text: faker.lorem.paragraphs (1),
			app: 'debes_flutter',
			version: '1.1.0',
			enabled: true,
			updated: now,
			created: now,
		};

		await firebase.firestore ().collection (InfoCollection).add (info);

		now = Date.now ();

		info = {
			type: IInfoType.TextOnly,
			languages: '',
			headline: 'From To',
			headlineSearchable: 'From To'.toLocaleLowerCase (),
			text: faker.lorem.paragraphs (1),
			from: 1614455131,
			to: 1614627931,
			enabled: true,
			updated: now,
			created: now,
		};

		await firebase.firestore ().collection (InfoCollection).add (info);
	}
}
