export const FeedbackCollection = 'feedbacks';

export default interface IFeedback {
    uid?: string;
    app: string;
    version?: string;
    platform?: string;
    name?: string;
    nameSearchable?: string;
    email?: string;
    emailSearchable?: string;
    subject?: string;
    subjectSearchable?: string;
    message: string;
    image?: string;
    status: IFeedbackStatus;
    updated: number;
    created: number;
}

export enum IFeedbackStatus {
    None,
    New,
    InProgress,
    Done,
    Closed,
}

/**
 * Convert the FeedbackStatus into text.
 */
export function FeedbackStatusToText (status: IFeedbackStatus): string {
    switch (status) {
        case IFeedbackStatus.New:
            return 'New';
        case IFeedbackStatus.InProgress:
            return 'In Progress';
        case IFeedbackStatus.Done:
            return 'Done';
        case IFeedbackStatus.Closed:
            return 'Closed';
        default:
            return 'N/A';
    }
}
