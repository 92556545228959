import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles ((theme: Theme) => ({
	appBarSpacer: theme.mixins.toolbar
}));

/**
 * Render the component into html.
 */
function AppBarSpacer () {
    const classes = useStyles ();
    
    return (
        <div className={classes.appBarSpacer}/>
    );
}

export default AppBarSpacer;
