/* eslint-disable no-whitespace-before-property */
import firebase from "firebase/app";
import "firebase/firestore";
import { IAppContext } from "../../App";
import IAggregate, { AggregateCollection } from "../Aggreagte";

/**
 * Fetch apps aggregate from Firestore.
 */
export async function FetchAppsAggregate (appContext: IAppContext): Promise<IAggregate | undefined> {
    try {
        const querySnapshot = await firebase.firestore ().collection (AggregateCollection)
            .where ('key', '==', 'apps-options')
            .limit (1)
            .get ();

        const newAggregates = {...appContext.aggregates};

        if (querySnapshot.empty) {
            newAggregates.apps = undefined;
        } else {
            const aggregate: IAggregate = querySnapshot.docs [0].data () as any;

            newAggregates.apps = aggregate;
        }

        appContext.SetAggregates (newAggregates);

        return newAggregates.apps;
    } catch (e) {
        console.error (`FetchAppsAggregate: ${e}`);
    }

    return undefined;
}
