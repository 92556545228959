const config = {
    "apiKey": "AIzaSyD-dCTz67RO5JVeRHO9FJQla5_Eug2po7M",
    "authDomain": "tchapps-4a4ee.firebaseapp.com",
    "projectId": "tchapps-4a4ee",
    "storageBucket": "tchapps-4a4ee.appspot.com",
    "messagingSenderId": "135363313709",
    "appId": "1:135363313709:web:6b529e942459b43c4b78a4",
    "measurementId": "G-5ND8LWGMCX"
};

export const defaultRegion = 'europe-west1';

export default config;
