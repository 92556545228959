/* eslint-disable no-whitespace-before-property */
import { Button, makeStyles, Theme } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import Cog from "../../../icons/Cog";
import { formUseStyles } from "../Form";
import { FileInputProps } from "./InputProps";

const useStyles = makeStyles ((theme: Theme) => ({
	error: {
		margin: 0,
		marginLeft: 14,
		marginRight: 14,
		color: '#f44336',
		fontSize: '0.75rem',
		fontWeight: 400,
	},
	fileInput: {
		display: 'flex',
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center',
		height: 56,
		marginTop: theme.spacing (2),
		marginBottom: theme.spacing (1),
		border: `1px solid rgba(0, 0, 0, 0.23)`,
		borderRadius: 4,
		color: 'rgba(0, 0, 0, 0.54)',
		cursor: 'pointer',
		"&:hover, &:focus": {
			borderColor: 'rgba(0, 0, 0, 0.87)',
			color: theme.palette.primary.main,
		},
	},
	fileInputError: {
		borderColor: '#f44336',
		"&:hover, &:focus": {
			borderColor: '#f44336',
		},
	},
	fileInputUploading: {
		borderColor: 'rgba(0, 0, 0, 0.87)',
		color: 'white',
		background: theme.palette.primary.main,
		"&:hover, &:focus": {
			color: 'white',
		},
	},
	fileInputImage: {
		height: 112,
		cursor: 'default',
	},
	fileInputContents: {
		verticalAlign: 'middle',
	},
	hidden: {
		display: 'none',
	},
	image: {
		height: 56,
	},
	space: {
		display: 'inline-block',
		width: theme.spacing (2),
	},
}));

/**
 * Render the component into html.
 */
function FileInput (props: FileInputProps) {
    const {index, className, label, value, UpdateProp, UploadFile, UpdateValue, FilePublicUrl, uploading, hidden, error, errorMessage} = props;

	const classes = useStyles ();

	const formClasses = formUseStyles ();

	const inputRef = useRef<HTMLInputElement> (null);

	const [fileUrl, setFileUrl] = useState<string> ();

	useEffect (() => {
		if (value === '' && fileUrl) {
			setFileUrl (undefined);
		} else if (value !== '' && !fileUrl) {
			FilePublicUrl (value)
			.then (newFileUrl => {
				setFileUrl (newFileUrl);
			})
			.catch (e => console.error (`FileInput: ${e}`));
		}
	}, [value]);

	/**
	 * Decide what to do when clicked.
	 */
	const OnClick = () => {
		if (!fileUrl) {
			ChooseFile ();
		}
	};

	/**
	 * Choose file using input.
	 */
	const ChooseFile = () => {
		if (inputRef.current) {
			inputRef.current.click ();
		}
	};

	/**
	 * File chosen, upload to API.
	 */
	const FileChosen = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (inputRef.current) {
			const htmlInput: HTMLInputElement = e.target;
			const file: string = htmlInput.value;

			if (file !== '' && htmlInput.files && htmlInput.files.length > 0) {
				UpdateProp (index, 'uploading', true);

				try {
					const newValue = await UploadFile (htmlInput.files);

					UpdateValue (index, newValue);

					const newFileUrl = await FilePublicUrl (newValue);

					setFileUrl (newFileUrl);
				} catch (e) {
					console.error (e);

					UpdateValue (index, '');
				}

				UpdateProp (index, 'uploading', false);
			}
		}
	};

	let contents: JSX.Element = (<div/>);

	if (uploading) {
		contents = (
			<div>
				<Cog spin={true}/>
	
				<input ref={inputRef} className={classes.hidden} type="file" accept="image/*" onChange={FileChosen}/>
			</div>
		);
	} else if (fileUrl) {
		contents = (
			<div>
				<a className={classes.fileInputContents} href={fileUrl} rel="noreferrer" target="_blank">
					<img className={classes.image} src={fileUrl} alt={value}/>
				</a>

				<div className={classes.space}/>

				<Button className={classes.fileInputContents} onClick={() => UpdateValue (index, '')}>Remove</Button>

				<div className={classes.space}/>

				<Button className={classes.fileInputContents} onClick={() => ChooseFile ()}>Replace</Button>

				<input ref={inputRef} className={classes.hidden} type="file" accept="image/*" onChange={FileChosen}/>
			</div>
		);
	} else {
		contents = (
			<div>
				<span className={classes.fileInputContents}>{label} </span><CloudUpload className={classes.fileInputContents}/>

				<input ref={inputRef} className={classes.hidden} type="file" accept="image/*" onChange={FileChosen}/>
			</div>
		);
	}

	const errorJSX = error ? (
		<p className={classes.error}>{errorMessage}</p>
	) : undefined;

	return (
		<div className={clsx ('Form_FileInput', className, (hidden ? formClasses.hidden : undefined))}>
			<div className={clsx (classes.fileInput, (uploading ? classes.fileInputUploading : undefined), (fileUrl ? classes.fileInputImage : undefined), (error ? classes.fileInputError : undefined))} onClick={() => OnClick ()}>
				{contents}
			</div>

			{errorJSX}
		</div>
	);
}

export default FileInput;
