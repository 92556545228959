import { Button, makeStyles, Theme } from "@material-ui/core";
import { DatePicker as PickersDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import { formUseStyles } from "../Form";
import { DatePickerProps } from "./InputProps";

const useStyles = makeStyles ((theme: Theme) => ({
    datePicker: {
        position: 'relative',
    },
    clear: {
        display: 'block',
        position: 'absolute',
        top: 26,
        right: 8,
    },
}));

/**
 * Render the component into html.
 */
function DatePicker (props: DatePickerProps) {
    const {className, hidden, id, index, label, value, error, errorMessage, UpdateValue} = props;

    const formClasses = formUseStyles ();
    const classes = useStyles ();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (date: MaterialUiPickersDate) => {
        if (date) {
            const theDate: Date = date.toDate ();

            UpdateValue (index, theDate.getTime ());
        } else {
            UpdateValue (index, undefined);
        }
    };

    const theValue = value ? new Date (value) : null;

    const clear = theValue ? (
        <div className={classes.clear}>
            <Button onClick={() => OnChange (null)}>Clear</Button>
        </div>
    ) : null;

    return (
        <div className={clsx ('Form_DatePicker', classes.datePicker, className, (hidden ? formClasses.hidden : undefined))}>
            <PickersDatePicker id={id} label={label} value={theValue} variant="dialog" inputVariant="outlined" error={error} helperText={error ? errorMessage : undefined} fullWidth={true} margin="normal" onChange={OnChange} disablePast={true}/>

            {clear}
        </div>
    );
}

export default DatePicker;
