import {useContext, useEffect} from "react";
import firebase from "firebase/app";
import "firebase/auth";
import {AppContext} from "../App";
import {kLoginRoute} from "./LoginScreen";
import {useHistory} from "react-router-dom";

export const kLogoutRoute = '/logout';

/**
 * Component which executes AuthUser log out process.
 */
function LogoutScreen () {
	const appContext = useContext (AppContext);
	const {authUser} = appContext;
	
	const history = useHistory ();

	/**
	 * SignOut currectly SignedIn user from Firebase Auth.
	 */
	const Logout = async () => {
		try {
			await firebase.auth ().signOut ();

			appContext.SetAuthUser (undefined);
		} catch (e) {
            console.error (e);
        }
	};

	useEffect (() => {
		appContext.SetTitle ('Logout');

		Logout ();
	}, []);

	useEffect (() => {
		if (!authUser) {
			history.push (kLoginRoute);
		}
	}, [authUser]);

	return null;
}

export default LogoutScreen;
