import { Button, Typography } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { FillDBForEmulator } from "../utils/Test";
import AppPaper from "./components/AppPaper";
import ScreenContent from "./components/ScreenContent";
import firebase from "firebase/app";
import "firebase/functions";
import { defaultRegion } from '../firebase-config';

export const kDashboardRoute = '/';

/**
 * Render the component into html.
 */
export default function DashboardScreen () {
    const appContext = useContext (AppContext);

    useEffect (() => {
        appContext.SetTitle ('Dashboard');
    }, []);

    const devContents = window.location.hostname === 'localhost' ? (
        <AppPaper>
            <Typography paragraph={true}>
                Fill the DB with dummy data.
            </Typography>

            <Button variant="outlined" onClick={() => FillDBForEmulator ()}>Start</Button>
        </AppPaper>
    ) : null;
    
    return (
        <ScreenContent>
            <>
                <AppPaper>
                    <Typography paragraph={true}>
                        Fetch current Apps data from my website.
                    </Typography>

                    <Button variant="outlined" onClick={() => FetchApps ()}>Fetch</Button>
                </AppPaper>

                {devContents}
            </>
        </ScreenContent>
    );
}

/**
 * Call microservice for update of apps from my website.
 */
async function FetchApps (): Promise<void> {
    return new Promise ((resolve, reject) => {
        const fetchApps = firebase.app ().functions (defaultRegion).httpsCallable ('apps-fetchApps');

        fetchApps ()
        .then (result => {
            resolve ();
        })
        .catch (e => {
            console.error (e);

            reject ();
        });
    });
}
