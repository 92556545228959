import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ui/components/ProtectedRoute";
import DashboardScreen, { kDashboardRoute } from "./ui/DashboardScreen";
import FeedbackListScreen, { kFeedbackListRoute } from "./ui/feedback/FeedbackListScreen";
import FeedbackScreen, { kFeedbackRoute } from "./ui/feedback/FeedbackScreen";
import InfoEditScreen, { kInfoCreateRoute, kInfoEditRoute } from "./ui/info/InfoEditScreen";
import InfoListScreen, { kInfoListRoute } from "./ui/info/InfoListScreen";
import InfoScreen, { kInfoRoute } from "./ui/info/InfoScreen";
import LoginScreen, { kLoginRoute } from "./ui/LoginScreen";
import LogoutScreen, { kLogoutRoute } from "./ui/LogoutScreen";
import NoMatchScreen from "./ui/NoMatchScreen";

/**
 * Router component history based routing.
 */
function Router () {
    return (
        <Switch>
            <Route path={kLoginRoute} component={LoginScreen}/>
            <Route path={kLogoutRoute} component={LogoutScreen}/>

            <ProtectedRoute exact={true} path={kDashboardRoute} redirectTo={kLoginRoute} component={DashboardScreen}/>

            <ProtectedRoute path={kInfoCreateRoute} redirectTo={kLoginRoute} component={InfoEditScreen}/>
            <ProtectedRoute path={kInfoEditRoute} redirectTo={kLoginRoute} component={InfoEditScreen}/>
            <ProtectedRoute path={kInfoRoute} redirectTo={kLoginRoute} component={InfoScreen}/>
            <ProtectedRoute path={kInfoListRoute} redirectTo={kLoginRoute} component={InfoListScreen}/>

            <ProtectedRoute path={kFeedbackRoute} redirectTo={kLoginRoute} component={FeedbackScreen}/>
            <ProtectedRoute path={kFeedbackListRoute} redirectTo={kLoginRoute} component={FeedbackListScreen}/>

            <Route component={NoMatchScreen}/>
        </Switch>
    );
}

export default Router;
