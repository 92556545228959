import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { formUseStyles } from "../Form";
import { InputProps } from "./InputProps";

/**
 * Render the component into html.
 */
function PasswordInput (props: InputProps) {
    const {index, id, className, label, value, UpdateValue, hidden, error, errorMessage} = props;

    const formClasses = formUseStyles ();

    /**
     * OnChange update data of this field.
     */
    const OnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        UpdateValue (index, e.target.value);
    };

    return (
        <div className={clsx ('Form_TextInput', className, (hidden ? formClasses.hidden : undefined))}>
            <TextField id={id} label={label} type="password" value={value} variant="outlined" error={error} helperText={error ? errorMessage : undefined} fullWidth={true} margin="normal" onChange={OnChange}/>
        </div>
    );
}

export default PasswordInput;
