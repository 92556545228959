/**
 * Truncate text for limit, optionally find nearest whitespace for whole words.
 */
export function TruncateText (text: string, limit: number, wholeWords: boolean = false, addDots: boolean = false, addSpaceDots: boolean = false): string {
    if (text.length > limit) {
        if (wholeWords) {
            const part = text.substring (0, limit);
            const whitespace = part.lastIndexOf ('');

            return part.substring (0, whitespace) + (addDots ? (addSpaceDots ? ' ...' : '...') : '');
        } else {
            return text.substring (0, limit) + (addDots ? (addSpaceDots ? ' ...' : '...') : '');
        }
    }

    return text;
}
