import {AppBar, Button, Grid, makeStyles, Theme, Toolbar, Typography} from "@material-ui/core";
import {useContext} from "react";
import {AppContext, appUseStyles} from "../../App";
import {useHistory} from "react-router-dom";
import {kLogoutRoute} from "../LogoutScreen";

const useStyles = makeStyles ((theme: Theme) => ({
    gridItem: {
        display: 'flex',
        alignItems: 'center',
    },
    userName: {
        display: 'inline-block',
        paddingRight: theme.spacing (2)
    }
}));

/**
 * Default screen AppBar component.
 */
export default function ScreenAppBar () {
    const appContext = useContext (AppContext);
    const {title, authUser} = appContext;

    const appClasses = appUseStyles ();
    const classes = useStyles ();

    const history = useHistory ();

    if (authUser) {
        return (
            <AppBar className={appClasses.appBar} position="fixed">
                <Toolbar>
                    <Grid container={true} justify="space-between">
                        <Grid item={true} className={classes.gridItem}>
                            <Typography component="h1">
                                {title}
                            </Typography>
                        </Grid>

                        <Grid item={true}>
                            <Typography className={classes.userName}>
                                {authUser.email}
                            </Typography>

                            <Button color="secondary" variant="contained" onClick={() => history.push (kLogoutRoute)}>Logout</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }

    return null;
}
