import ITranslation from "./Translation";

export const InfoCollection = 'infos';

export default interface IInfo {
    uid?: string;
    type: IInfoType;
    languages: string;
    headline: string;
    headlineSearchable: string;
    headlineTranslations?: ITranslation [];
    fullImage?: string;
    image?: string;
    text?: string;
    textTranslations?: ITranslation [];
    app?: string;
    version?: string;
    from?: number;
    to?: number;
    enabled?: boolean;
    debugOnly?: boolean;
    url?: string;
    payload?: string;
    updated: number;
    created: number;
}

export enum IInfoType {
    None,
    TextOnly,
    ImageText,
    FullImageOnly,
}

/**
 * InfoType to text description.
 */
export function InfoTypeToString (type: IInfoType):string {
    switch (type) {
        case IInfoType.None:
            return 'None';
        case IInfoType.TextOnly:
            return 'Text only';
        case IInfoType.ImageText:
            return ' Image & Text';
        case IInfoType.FullImageOnly:
            return 'FullImage only';
    }
}
