import { Button, makeStyles, Table, TableBody, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import { ChevronLeft, Delete, Edit } from "@material-ui/icons";
import {Link, useHistory, useParams} from "react-router-dom";
import ActionsPanel from "../components/ActionsPanel";
import ScreenContent from "../components/ScreenContent";
import firebase from "firebase/app";
import "firebase/firestore";
import IInfo, { InfoCollection, InfoTypeToString } from "../../model/Info";
import { useContext, useEffect, useState } from "react";
import { AppContext, IAppContext } from "../../App";
import AppPaper from "../components/AppPaper";
import { FilePublicUrl } from "../../model/providers/StorageProvider";

export const kInfoRoute = '/info/:infoId';

export const useStyles = makeStyles ((theme: Theme) => ({
	tableImage: {
		height: 48,
	},
	space: {
		display: 'inline-block',
		width: theme.spacing (2),
	},
}));

export interface Params {
    infoId: string;
}

/**
 * Render the component into html.
 */
function InfoScreen () {
    const {infoId} = useParams<Params> ();

    const appContext = useContext (AppContext);

    const history = useHistory ();

    const classes = useStyles ();

    useEffect (() => {
        appContext.SetTitle ('Info Detail');
    }, []);

    const [info, setInfo] = useState<IInfo> ();
    const [fullImageUrl, setFullImageUrl] = useState<string> ();
    const [imageUrl, setImageUrl] = useState<string> ();

    useEffect (() => {
        const unsubscribe = firebase.firestore ().collection (InfoCollection)
        .doc (infoId)
        .onSnapshot (snapshot => {
            if (snapshot.exists) {
                const snapshotInfo: IInfo = snapshot.data () as any;
                snapshotInfo.uid = snapshot.id;

                setInfo (snapshotInfo);

                if (snapshotInfo.fullImage) {
                    FilePublicUrl (snapshotInfo.fullImage)
                    .then (url => {
                        setFullImageUrl (url);
                    })
                    .catch (e => console.error (e));
                }

                if (snapshotInfo.image) {
                    FilePublicUrl (snapshotInfo.image)
                    .then (url => {
                        setImageUrl (url);
                    })
                    .catch (e => console.error (e));
                }
            } else {
                setInfo (undefined);
            }
        });

        return () => {
            unsubscribe ();
        };
    }, []);

    const fullImage = info && info.fullImage && fullImageUrl ? (
        <a href={fullImageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={fullImageUrl} alt={info.fullImage}/>
        </a>
    ) : 'N/A';

    const image = info && info.image && imageUrl ? (
        <a href={imageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={imageUrl} alt={info.image}/>
        </a>
    ) : 'N/A';

    const tableItems = info ? (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2} align="center">
                        <Typography variant="h4">
                            {info.headline} - {InfoTypeToString (info.type)}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            FullImage:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {fullImage}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Image:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {image}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Text:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.text || 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">
                            Conditions for display
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            App:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.app || 'All apps'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Version:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.version || 'All versions'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            From:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.from ? new Date (info.from * 1000).toDateString () : 'UnRestricted'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            To:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.to ? new Date (info.to * 1000).toDateString () : 'UnRestricted'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Enabled:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.enabled ? 'Enabled' : 'Disabled'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Debug build only:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.debugOnly ? 'Enabled' : 'Disabled'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">
                            Data for actions
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Url:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.url ? <a href={info.url} rel="noreferrer" target="_blank">{info.url}</a> : 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Payload:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {info.payload || 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    ) : (
        <Typography>
            Info does not exist
        </Typography>
    );

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} leftAction={
                <Button variant="outlined" color="secondary" component={Link} to="/info"><ChevronLeft/></Button>
            } rightAction={
                <>
                    <Button variant="contained" color="secondary" onClick={() => DeleteInfo (appContext, infoId, (to: string) => history.push (to))}><Delete/></Button>

                    <div className={classes.space}/>

                    <Button variant="contained" color="primary" onClick={() => history.push (`/info/${infoId}/edit`)}><Edit/></Button>
                </>
            }/>

            <AppPaper>
                {tableItems}
            </AppPaper>
        </ScreenContent>
    );
}

/**
 * Delete Info from DB by id.
 */
function DeleteInfo (appContext: IAppContext, uid: string, setRedirectTo: (to: string) => void) {
    appContext.SetModals ({
        confirm: {
            open: true,
            text: 'Do you want to delete this Info?',
            onConfirm: async (confirmed: boolean) => {
                if (confirmed) {
                    await firebase.firestore ().collection (InfoCollection).doc (uid).delete ();

                    setRedirectTo ('/info');
                }
            },
        },
    });
}

export default InfoScreen;
