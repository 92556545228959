import { makeStyles, Paper, Theme } from "@material-ui/core";

export const useStyles = makeStyles ((theme: Theme) => ({
	paper: {
		padding: theme.spacing (1),
		marginBottom: theme.spacing (2)
	},
}));

export interface Props {
	children?: JSX.Element | JSX.Element [];
}

/**
 * Render the component into html.
 */
function AppPaper (props: Props) {
	const {children} = props;

	const classes = useStyles ();

	return (
		<Paper className={classes.paper}>
			{children}
		</Paper>
	);
}

export default AppPaper;
