import { Button, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { AppContext, IAppContext } from "../../App";
import ActionsPanel from "../components/ActionsPanel";
import AppPaper from "../components/AppPaper";
import ScreenContent from "../components/ScreenContent";
import firebase from "firebase/app";
import "firebase/firestore";
import IInfo, { InfoCollection, InfoTypeToString } from "../../model/Info";
import { TruncateText } from "../../utils/Text";
import { ChevronRight, Delete } from "@material-ui/icons";
import { FilePublicUrl } from "../../model/providers/StorageProvider";
import {useHistory} from "react-router-dom";

export const kInfoListRoute = '/info';

export const useStyles = makeStyles ((theme: Theme) => ({
	tableImage: {
		height: 48,
	},
}));

/**
 * Render the component into html.
 */
function InfoListScreen () {
    const appContext = useContext (AppContext);

    useEffect (() => {
        appContext.SetTitle ('Info');
    }, []);

    const history = useHistory ();

    let defaultLimit = appContext.infos.limit;
    if (window.location.hash) {
        const hash = window.location.hash.replace ('#', '').split ('&').map (params => params.split ('='));

        if (hash && hash.length > 0) {
            for (const params of hash) {
                if (params [0] === 'limit') {
                    defaultLimit = parseInt (params [1]);
                }
            }
        }
    }

    useEffect (() => {
        if (defaultLimit !== appContext.infos.limit) {
            const newInfos = {...appContext.infos};
            newInfos.limit = defaultLimit;
    
            appContext.SetInfos (newInfos);
        }
    }, []);

    useEffect (() => {
        const unsubscribe = firebase.firestore ().collection (InfoCollection)
        .orderBy ('created', 'desc')
        .limit (appContext.infos.limit)
        .onSnapshot (snapshot => {
            if (snapshot.empty) {
                UpdateData (appContext, []);
            } else {
                UpdateData (appContext, snapshot.docs.map (doc => {
                    const info: IInfo = doc.data () as any;
                    info.uid = doc.id;

                    return info;
                }));
            }
        });

        return () => {
            unsubscribe ();
        };
    }, [appContext.infos.limit]);

    const tableItems = appContext.infos.data && appContext.infos.data.length > 0 ? appContext.infos.data.map (info => {
        return (
            <InfoTableRow key={`info-${info.uid}`} info={info} SetRedirectTo={(to: string) => history.push (to)}/>
        );
    }) : (
        <TableRow>
            <TableCell colSpan={6} align="center">
                {appContext.infos.data ? 'No Info found' : 'Loading...'}
            </TableCell>
        </TableRow>
    );

    const rightAction = appContext.infos.data && appContext.infos.data.length >= appContext.infos.limit ? (
        <Button variant="outlined" onClick={() => LoadMore (appContext)}>More <ChevronRight/></Button>
    ) : undefined;

    return (
        <ScreenContent>
            <ActionsPanel marginBottom={true} rightAction={
                <Button variant="contained" color="primary" onClick={() => history.push ('/info/create')}>Create Info</Button>
            }/>

            <AppPaper>
                <Table stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Headline</TableCell>
                            <TableCell>FullImage</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell width="130px" align="center">
                                <Button onClick={() => ResetInfos (appContext)}>Reset</Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableItems}
                    </TableBody>
                </Table>

                <ActionsPanel rightAction={rightAction} marginTop={true}/>
            </AppPaper>
        </ScreenContent>
    );
}

/**
 * Delete Info from DB by id.
 */
function DeleteInfo (appContext: IAppContext, uid: string) {
    appContext.SetModals ({
        confirm: {
            open: true,
            text: 'Do you want to delete this Info?',
            onConfirm: (confirmed: boolean) => {
                if (confirmed) {
                    firebase.firestore ().collection (InfoCollection).doc (uid).delete ();
                }
            },
        },
    });
}

/**
 * Update infos data for snapshot.
 */
function UpdateData (appContext: IAppContext, infos: IInfo []) {
    const newInfos = {...appContext.infos};
    newInfos.data = infos;

    appContext.SetInfos (newInfos);
}

/**
 * Load more infos by one page.
 */
function LoadMore (appContext: IAppContext) {
    const newInfos = {...appContext.infos};
    newInfos.limit = newInfos.limit + 10;

    appContext.SetInfos (newInfos);
}

/**
 * Reset filters, pagination to default state.
 */
function ResetInfos (appContext: IAppContext) {
    const newInfos = {...appContext.infos};
    newInfos.limit = 10;

    appContext.SetInfos (newInfos);
}

interface InfoTableRowProps {
    info: IInfo;
    SetRedirectTo: (to: string) => void;
}

/**
 * Render the component into html.
 */
function InfoTableRow (props: InfoTableRowProps) {
    const {info, SetRedirectTo} = props;

    const appContext = useContext (AppContext);

    const classes = useStyles ();

    const [fullImageUrl, setFullImageUrl] = useState<string> ();
    const [imageUrl, setImageUrl] = useState<string> ();

    useEffect (() => {
        if (info.fullImage) {
            FilePublicUrl (info.fullImage)
            .then (url => {
                setFullImageUrl (url);
            })
            .catch (e => console.error (e));
        }
    }, []);

    useEffect (() => {
        if (info.image) {
            FilePublicUrl (info.image)
            .then (url => {
                setImageUrl (url);
            })
            .catch (e => console.error (e));
        }
    }, []);

    const fullImage = fullImageUrl ? (
        <a href={fullImageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={fullImageUrl} alt={info.fullImage}/>
        </a>
    ) : 'N/A';

    const image = imageUrl ? (
        <a href={imageUrl} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={imageUrl} alt={info.image}/>
        </a>
    ) : 'N/A';

    const text = info.text ? TruncateText (info.text, 60, true, true) : 'N/A';

    const url = info.url ? (
        <a href={info.url} rel="noreferrer" target="_blank">{info.url}</a>
    ) : 'N/A';

    return (
        <TableRow>
            <TableCell>{InfoTypeToString (info.type)}</TableCell>
            <TableCell>{info.headline}</TableCell>
            <TableCell>{fullImage}</TableCell>
            <TableCell>{image}</TableCell>
            <TableCell>{text}</TableCell>
            <TableCell align="right" width="130px">
                <IconButton onClick={() => DeleteInfo (appContext, info.uid!)}><Delete/></IconButton>
                <IconButton onClick={() => SetRedirectTo (`/info/${info.uid}`)}><ChevronRight/></IconButton>
            </TableCell>
        </TableRow>
    );
}

export default InfoListScreen;
