import { Button, makeStyles, Table, TableBody, TableCell, TableRow, Theme, Typography } from "@material-ui/core";
import { ChevronLeft, Delete } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import { AppContext, IAppContext } from "../../App";
import ActionsPanel from "../components/ActionsPanel";
import ScreenContent from "../components/ScreenContent";
import firebase from "firebase/app";
import "firebase/firestore";
import IFeedback, { FeedbackCollection, IFeedbackStatus, FeedbackStatusToText } from "../../model/Feedback";
import AppPaper from "../components/AppPaper";
import AppModal from "../components/AppModal";
import Form, { InputsData, InputType, ValidateForm } from "../components/Form";

export const kFeedbackRoute = '/feedback/:feedbackId';

export const useStyles = makeStyles ((theme: Theme) => ({
	tableImage: {
		height: 48,
	},
    action: {
        marginRight: theme.spacing (1),
    },
}));

export interface Params {
    feedbackId: string;
}

/**
 * Render the component into html.
 */
function FeedbackScreen () {
    const {feedbackId} = useParams<Params> ();

    const appContext = useContext (AppContext);

    const history = useHistory ();

    const classes = useStyles ();

    useEffect (() => {
        appContext.SetTitle ('Feedback Detail');
    }, []);

    const [feedback, setFeedback] = useState<IFeedback> ();

    useEffect (() => {
        const unsubscribe = firebase.firestore ().collection (FeedbackCollection)
        .doc (feedbackId)
        .onSnapshot (snapshot => {
            if (snapshot.exists) {
                const snapshotFeedback: IFeedback = snapshot.data () as any;
                snapshotFeedback.uid = snapshot.id;

                const newInputs = {...inputs};
                newInputs.status.value = snapshotFeedback.status;

                setFeedback (snapshotFeedback);

                setInputs (newInputs);
            } else {
                setFeedback (undefined);
            }
        });

        return () => {
            unsubscribe ();
        };
    }, []);

    const [statusModal, setStatusModal] = useState<boolean> (false);
    const [inputs, setInputs] = useState<InputsData> ({
        status: {
            type: InputType.Select,
            label: 'Status',
            value: '',
            required: true,
            options: [
                {
                    label: FeedbackStatusToText (IFeedbackStatus.New),
                    value: IFeedbackStatus.New,
                },
                {
                    label: FeedbackStatusToText (IFeedbackStatus.InProgress),
                    value: IFeedbackStatus.InProgress,
                },
                {
                    label: FeedbackStatusToText (IFeedbackStatus.Done),
                    value: IFeedbackStatus.Done,
                },
                {
                    label: FeedbackStatusToText (IFeedbackStatus.Closed),
                    value: IFeedbackStatus.Closed,
                }
            ],
        },
    });

    const image = feedback && feedback.image ? (
        <a href={feedback.image} rel="noreferrer" target="_blank">
            <img className={classes.tableImage} src={`${feedback.image}?v=${feedback.uid}`} alt={feedback.image}/>
        </a>
    ) : 'N/A';

    const tableItems = feedback ? (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2} align="center">
                        <Typography variant="h4">
                            {feedback.name || 'Anonymous'} - {FeedbackStatusToText (feedback.status)}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            App:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.app}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Version:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.version || 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Platform:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.platform || 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Email:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.email ? <a href={`mailto:${feedback.email}`} rel="noreferrer" target="_blank">{feedback.email}</a> : 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Subject:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.subject || 'N/A'}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Message:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {feedback.message}
                        </Typography>
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell>
                        <Typography variant="h6">
                            Image:
                        </Typography>
                    </TableCell>

                    <TableCell>
                        <Typography>
                            {image}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    ) : (
        <Typography>
            Feedback does not exist
        </Typography>
    );

    /**
     * Save new status for Feedback to Firestore.
     */
    const SetStatus = async () => {
        if (ValidateForm (inputs, (inputs: InputsData) => setInputs (inputs)) && feedback) {
            const now = Date.now ();

            const newFeedback = {...feedback};

            newFeedback.status = inputs.status.value;
            newFeedback.updated = now;

            try {
                await firebase.firestore ().collection (FeedbackCollection)
                .doc (feedback.uid)
                .set (newFeedback);

                setFeedback (newFeedback);

                setStatusModal (false);
            } catch (e) {
                console.error (e);
            }
        }
    };

    return (
        <>
            <ScreenContent>
                <ActionsPanel marginBottom={true} leftAction={
                    <Button variant="outlined" color="secondary" component={Link} to="/feedback"><ChevronLeft/></Button>
                } rightAction={
                    <>
                        <Button className={classes.action} variant="outlined" onClick={() => setStatusModal (true)}>Set Status</Button>
                    
                        <Button variant="contained" color="secondary" onClick={() => DeleteFeedback (appContext, feedbackId, (to: string) => history.push (to))}><Delete/></Button>
                    </>
                }/>

                <AppPaper>
                    {tableItems}
                </AppPaper>
            </ScreenContent>

            <AppModal open={statusModal} body={(
                <Form id="info-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>
            )} actions={(
                <>
                    <Button key="action-cancel" className={classes.action} variant="contained" onClick={() => setStatusModal (false)}>Cancel</Button>

                    <Button key="action-save" variant="contained" color="primary" onClick={SetStatus}>Save</Button>
                </>
            )}/>
        </>
    );
}

/**
 * Delete Feedback from DB by id.
 */
function DeleteFeedback (appContext: IAppContext, uid: string, setRedirectTo: (to: string) => void) {
    appContext.SetModals ({
        confirm: {
            open: true,
            text: 'Do you want to delete this Feedback?',
            onConfirm: async (confirmed: boolean) => {
                if (confirmed) {
                    await firebase.firestore ().collection (FeedbackCollection).doc (uid).delete ();

                    setRedirectTo ('/feedback');
                }
            },
        },
    });
}

export default FeedbackScreen;
