import { Input, MenuItem, TextField } from "@material-ui/core";
import clsx from "clsx";
import { formUseStyles } from "../Form";
import { SelectProps } from "./InputProps";

/**
 * Render the component into html.
 */
function Select (props: SelectProps) {
	const {index, id, className, label, value, UpdateValue, options, OnChange, hidden, error, errorMessage} = props;

    const formClasses = formUseStyles ();

	/**
	 * OnChange update data of this field.
	 */
	const OnChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
		UpdateValue (index, e.target.value);

		if (OnChange) {
			OnChange (index, e.target.value);
		}
	};

	const menuItems = options.map (option => (
		<MenuItem key={`${id}-${option.value}`} value={option.value}>{option.label}</MenuItem>
	));

	return (
		<div className={clsx ('Form_Select', className, (hidden ? formClasses.hidden : undefined))}>
			<TextField id={id} label={label} value={value} variant="outlined" error={error} helperText={error ? errorMessage : undefined} fullWidth={true} margin="normal" select={true} onChange={OnChangeField}>
				{menuItems}
			</TextField>
		</div>
	);
}

export default Select;
