import { Button, Typography } from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/auth";
import { useContext, useEffect, useState } from "react";
import {useHistory } from "react-router-dom";
import { AppContext } from "../App";
import IAuthUser from "../model/AuthUser";
import ActionsPanel from "./components/ActionsPanel";
import AppPaper from "./components/AppPaper";
import Form, { InputsData, InputType } from "./components/Form";
import ScreenContent from "./components/ScreenContent";
import ScreenHeader from "./components/ScreenHeader";
import {kDashboardRoute} from "./DashboardScreen";

export const kLoginRoute = '/login';

/**
 * Component representing Login screen.
 */
function LoginScreen () {
    const appContext = useContext (AppContext);
    const {authUser} = appContext;

    const history = useHistory ();

    useEffect (() => {
        appContext.SetTitle ('Login');
    }, []);

    useEffect (() => {
        if (authUser) {
            history.push (kDashboardRoute);
        }
    }, [authUser]);

    const [inputs, setInputs] = useState<InputsData> ({
        username: {
            type: InputType.Text,
            label: 'Username',
            value: '',
        },
        password: {
            type: InputType.Password,
            label: 'Password',
            value: '',
        },
    });

    const [errorMsg, setErrorMsg] = useState<string> ();

    /**
     * SignIn the user using credentials againt Firebase Auth.
     */
    const Login = async () => {
        setErrorMsg (undefined);

        try {
            const userCredentials = await firebase.auth ().signInWithEmailAndPassword (inputs.username.value, inputs.password.value);

            const authUser: IAuthUser = {
                email: userCredentials.user!.email!,
                emailVerified: userCredentials.user!.emailVerified,
                uid: userCredentials.user!.uid,
            };

            appContext.SetAuthUser (authUser);
        } catch (e) {
            console.error (e);
            
            setErrorMsg ('Incorrect credentials');
        }
    };

    const errorJsx = errorMsg ? (
        <Typography color="error" align="right" paragraph={true}>
            {errorMsg}
        </Typography>
    ) : (
        <></>
    );
    
    return (
        <ScreenContent maxWidth="sm" centered={true}>
            <AppPaper>
                <ScreenHeader title="TChApps"/>

                <Form id="login-form" inputs={inputs} SetInputs={(inputs: InputsData) => setInputs (inputs)}/>

                {errorJsx}

                <ActionsPanel rightAction={
                    <Button variant="contained" color="primary" onClick={Login}>Login</Button>
                }/>
            </AppPaper>
        </ScreenContent>
    );
}

export default LoginScreen;
